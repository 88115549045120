import React, { FC, useEffect, useState } from "react";
import { useStaticQuery, graphql, Script } from "gatsby";
import AboutContent from "../../components/AboutContent";
import Layout from "../../components/layout/Layout";
import Seo from "../../components/Seo";
import { getHead } from "../../services/rankMathServices";
import parse from "html-react-parser";
import Clickmagic from "../../components/common/ClickMagic/Clickmagic";

const AboutPage: FC<any> = ({ data }) => {

  const aboutData = data?.allWpPage?.nodes[0];
  // const seoInfo: any = data?.allWpPage?.nodes[0]?.seo?.fullHead;

  const [info, setInfo] = useState(null);
  // useEffect(() => {
  //   getHead("about").then((data: any) => { setInfo(data.head) })
  // }, [])

  return (
    <>
      <AboutContent pageData={aboutData} />
    </>
  )
}

export default AboutPage;

export const query = graphql`
{
  allWpPage(filter: {slug: {eq: "about"}}) {
    nodes {
      title
      slug
      content
      seo{
        fullHead
      }
      featuredImage {
        node {
          sourceUrl
          altText
        }
      }
      aboutPage {
        banner {
          description
          bannerImage {
            node {
              sourceUrl
              altText
            }
          }
          bannerBackgroundImage {
            node {
              sourceUrl
              altText
            }
          }
        }
        bottomBanner {
          description
        }
        pencilBanner {
          optionOneIcon {
            node {
              sourceUrl
              altText
            }
          }
          optionOneTitle
          optionTwoIcon {
            node {
              sourceUrl
              altText
            }
          }
          optionTwoTitle
          optionThreeIcon {
            node {
              sourceUrl
              altText
            }
          }
          optionThreeTitle
          optionFourTitle
          optionFourIcon {
            node {
              sourceUrl
              altText
            }
          }
          optionFiveTitle
          optionFiveIcon {
            node {
              sourceUrl
              altText
            }
          }
        }
        whyChoose {
          description
          image {
            node {
              sourceUrl
              altText
            }
          }
        }
      }
    }
  }
}
`

export const Head = (props: any) => {
  const { data } = props;
  const seoInfo = data.allWpPage.nodes[0].seo.fullHead;
  const parseHtml: any = parse(seoInfo);

  const index =
    parseHtml &&
    parseHtml?.length > 0 &&
    parseHtml.findIndex(
      (item: any) => item?.props?.type === "application/ld+json"
    );

  if (index > -1) {
    parseHtml.splice(index, 1);
  }
  return (
    <>
      {parseHtml}
      <Seo info={seoInfo} />
    </>
  )
}