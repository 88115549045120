import React, { FC } from "react";
import Marquee from "react-fast-marquee";

interface props {
  pageData: any;
}

const BannerTop: FC<props> = ({ pageData }) => {
  const data = pageData?.pencilBanner;
  return (
    <div>
      <div className="px-[27px] mt-[0px] lg:mb-[25px] mb-[15px]  lg:rounded-[21px] rounded-[12px] bg-bg-grey cursor-pointer ">
        <div className="banner-top flex gap-[40px] justify-center lg:h-[87px] h-[58px] min-w-full  items-center "> 
          <Marquee className="" pauseOnHover={true}>
            <div className="flex items-center gap-2 cursor-pointer xl:mr-[150px] lg:mr-[60px] mr-[30px]">
              <span>
                <img
                  src={
                    data?.optionOneIcon?.node?.sourceUrl
                      ? data?.optionOneIcon?.node?.sourceUrl
                      : `${process.env.GATSBY_SITE_URL}/wp-content/uploads/2024/10/heart-1.png`
                     
                  }
                 // alt={data?.optionFiveTitle}
                  alt=  {data?.optionOneTitle
                    ? data?.optionOneTitle
                    : "99% Pure Peptides"}
                  className="md:w-[30px] w-[25px]"
                  width={30}
                  height={30}
                  loading="lazy"
                />
              </span>
              <span className=" font-sofiaMedium font-medium lg:text-[19px] text-[17px] md:hover:text-primary">
                {data?.optionOneTitle
                  ? data?.optionOneTitle
                  : "99% Pure Peptides"}
              </span>
            </div>
            <div className="flex items-center gap-2 cursor-pointer xl:mr-[150px] lg:mr-[60px] mr-[30px]">
              <span>
                <img
                  src={
                    data?.optionTwoIcon?.node?.sourceUrl
                      ? data?.optionTwoIcon?.node?.sourceUrl
                      : `${process.env.GATSBY_SITE_URL}/wp-content/uploads/2024/10/shipping-1.png`
                  }
                  //alt={data?.optionTwoTitle}
                  alt=   {data?.optionTwoTitle ? data?.optionTwoTitle : "Free Shipping"}
                  className="md:w-[30px] w-[25px]"
                  width={30}
                  height={30}
                />
              </span>
              <span className=" font-sofiaMedium font-medium lg:text-[19px] text-[17px md:hover:text-primary">
                {data?.optionTwoTitle ? data?.optionTwoTitle : "Free Shipping"}
              </span>
            </div>
            <div className="flex items-center gap-2 cursor-pointer xl:mr-[150px] lg:mr-[60px] mr-[30px]">
              <span>
                <img
                  src={
                    data?.optionFiveIcon?.node?.sourceUrl
                      ? data?.optionFiveIcon?.node?.sourceUrl
                      : `${process.env.GATSBY_SITE_URL}/wp-content/uploads/2024/10/heart-1.png`
                  }
                  //alt={data?.optionThreeTitle}
                  alt={data?.optionThreeTitle
                    ? data?.optionThreeTitle
                    : "Dedicated Services"}
                  className="md:w-[30px] w-[25px]"
                  width={30}
                  height={30}
                />
              </span>
              <span className=" font-sofiaMedium font-medium lg:text-[19px] text-[17px md:hover:text-primary">
                {data?.optionThreeTitle
                  ? data?.optionThreeTitle
                  : "Dedicated Services"}
              </span>
            </div>
            <div className="flex items-center gap-2 cursor-pointer xl:mr-[150px] lg:mr-[60px] mr-[30px]">
              <span>
                <img
                  src={
                    data?.optionFourIcon?.node?.sourceUrl
                      ? data?.optionFourIcon?.node?.sourceUrl
                      : `${process.env.GATSBY_SITE_URL}/wp-content/uploads/2024/10/24-7-1.png`
                  }
                  //alt={data?.optionFiveTitle}
                  alt="24/7 service"
                  className="md:w-[30px] w-[25px]"
                  width={30}
                  height={30}
                />
              </span>
              <span className=" font-sofiaMedium font-medium lg:text-[19px] text-[17px md:hover:text-primary">
                {data?.optionFourTitle
                  ? data?.optionFourTitle
                  : "Same Day Shipping"}
              </span>
            </div>
            <div className="flex items-center gap-2 cursor-pointer xl:mr-[150px] lg:mr-[60px] mr-[30px]">
              <span>
                <img
                  src={
                    data?.optionFiveIcon?.node?.sourceUrl
                      ? data?.optionFiveIcon?.node?.sourceUrl
                      : `${process.env.GATSBY_SITE_URL}/wp-content/uploads/2024/10/credit-card-1.png`
                  }
                  //alt={data?.optionFiveTitle}
                  alt= {data?.optionFiveTitle
                    ? data?.optionFiveTitle
                    : "Secure Payment Option"}
                  className="md:w-[30px] w-[25px]"
                  width={30}
                  height={30}
                />
              </span>
              <span className=" font-sofiaMedium font-medium lg:text-[19px] text-[17px] md:hover:text-primary">
                {data?.optionFiveTitle
                  ? data?.optionFiveTitle
                  : "Secure Payment Option"}
              </span>
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default BannerTop;
