import React, { FC } from "react";
import BannerTop from "./BannerTop";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface props {
  pageData: any;
}

const AboutBanner: FC<props> = ({ pageData }) => {
  const bannerData = pageData?.aboutPage?.banner;
  const aboutData = pageData?.aboutPage;
  // console.log('Banner data', pageData);
  return (
    <div className="about_banner_wrapper">
      <BannerTop pageData={aboutData} />
      <div className="about_banner_div xsm:flex md:block w-full !bg-bg-grey lg:rounded-[21px] rounded-[12px] lg:px-[50px]  px-[15px] mb-[30px]">
        {/* <img
                    src={bannerData?.bannerBackgroundImage?.node?.sourceUrl}
                    width={500}
                    height={409}
                    className="w-full object-contain"
                    alt={ 
                        bannerData.bannerBackgroundImage.node.sourceUrl
                        ? bannerData?.bannerBackgroundImage?.node?.altText
                        : "Spartan About Banner"
                    }
                /> */}
        <div className="banner_content_div flex md:flex-row flex-col justify-between items-center">
          <div
            className="banner_description my-auto"
            dangerouslySetInnerHTML={{ __html: bannerData?.description }}
          ></div>
          <div className="banner_right_img mr-[100px] xsm:hidden md:block">
            <LazyLoadImage
              effect="blur"
              src={
                bannerData?.bannerImage?.node?.sourceUrl
                  ? bannerData?.bannerImage?.node?.sourceUrl
                  : `${process.env.GATSBY_SITE_URL}/wp-content/uploads/2024/08/no-image-sq.png`
              }
              alt={
                bannerData.bannerImage.node.sourceUrl
                  ? bannerData?.bannerImage?.node?.altText
                  : "Spartan About Banner"
              }
            />

          
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutBanner;
